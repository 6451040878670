<script>
import { defineComponent } from 'vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { DHME_MANUAL_IMPORT } from '@/modules/modules';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'DHMEManualImport',
  components: { ModuleNavigationBar },
  data: () => ({
    moduleTab: null, // Current active tab index
    tabConfigs: [], // Array holding the visible tab configuration (title and route)
  }),
  computed: {
    ...mapGetters({
      project: 'project',
      authenticatedUser: 'authenticatedUser',
      isLoading: 'dhmeManualImportStore/isLoading',
      isBim: 'dhmeManualImportStore/isBim',
    }),
  },
  watch: {
    moduleTab(value) {
      const currentTab = this.tabConfigs[value]; // Get the currently selected tab config
      if (currentTab && this.$route.name !== currentTab.route) {
        this.$router.push({ name: currentTab.route });
      }
    },
    isBim() {
      // Rebuild the tab configurations when `isBim` changes
      this.updateTabConfigs();
    },
  },
  async mounted() {
    this.updateTabConfigs(); // Initialize the tab configuration
    const currentTabIndex = this.tabConfigs.findIndex(
      (tab) => tab.route === this.$route.name
    );

    // Set the active tab based on the current route, defaulting to the first tab
    this.moduleTab = currentTabIndex !== -1 ? currentTabIndex : 0;
  },
  methods: {
    // Generate the tab configuration dynamically based on isBim
    updateTabConfigs() {
      this.tabConfigs = [
        { title: 'Modules', route: `${DHME_MANUAL_IMPORT}-modules` }, // Always present
        !this.isBim && {
          title: 'Module Contents',
          route: `${DHME_MANUAL_IMPORT}-module-contents`,
        },
        !this.isBim && {
          title: 'Element Contents',
          route: `${DHME_MANUAL_IMPORT}-element-contents`,
        },
        !this.isBim && {
          title: 'Objects',
          route: `${DHME_MANUAL_IMPORT}-objects`,
        },
        { title: 'Drawings', route: `${DHME_MANUAL_IMPORT}-drawings` }, // Always present
      ].filter(Boolean); // Remove `null`/`false` entries

      // Revalidate `moduleTab` in case the current tab is no longer visible
      if (!this.tabConfigs[this.moduleTab]) {
        this.moduleTab = 0;
      }
    },
  },
});
</script>

<template>
  <div class="d-flex flex-column overflow-y-auto">
    <module-navigation-bar title="Manual import">
      <template #module-nav-actions>
        <v-tabs
          v-model="moduleTab"
          background-color="transparent"
          height="46"
          style="width: auto"
        >
          <!-- Dynamically render tabs based on config -->
          <v-tab v-for="(tab, index) in tabConfigs" :key="index">{{
            tab.title
          }}</v-tab>
        </v-tabs>
      </template>
    </module-navigation-bar>
    <div class="flex-grow-1 d-flex flex-column overflow-y-auto">
      <v-progress-linear
        v-show="isLoading"
        color="primary"
        height="5px"
        indeterminate
      />
      <Transition mode="out-in" name="simple-fade">
        <router-view />
      </Transition>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
