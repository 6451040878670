<script>
import { defineComponent } from 'vue';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog.vue';
import FileHandlerService from '@/services/file-handler';
import { ANT_DELIMITER } from '@/services/uuid-helper';
import { moduleHeaders } from '@/modules/daiwa-house-modular-europe/ManualImport/columns';
import AntInput from '@/components/AntInput.vue';
import { bookOfObjects } from '@/services/bookOf';
import {
  deleteTaskAppendix,
  queryTasksV2,
  uploadTaskAppendix,
} from '@/services/api/task.api';
import { deleteRecords, importRecords } from '@/services/api/record.api';
import { queryTablesV2 } from '@/services/api/v2/connect_v2';

function debounce(fn, delay = 300) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
}

export default defineComponent({
  name: 'DhmeManualImportModules',
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      newBuildNr: null,
      moduleToDelete: null,
      manualImportModuleHeaders: moduleHeaders,
      isLoading: false,
      selectedCell: null,
      cellValue: null,
      csvImportFile: undefined,
      deleteMode: false,
      currentCellValue: null,
      ctrlAltDown: false,
      moduleAssemblyTasks: [],
      importMenu: false,
      importLoading: false,
      ignoreModuleTypes: false,
      batchFiles: [],
      batchCounter: [],
      // NEW: local store for pending edits (for all fields except 2d_drawing)
      updatesQueue: {},
      workloadMenu: false,
      workloadLoading: false,
      workloadValue: 32,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      modules: 'dhmeManualImportStore/modules',
      modulesTableId: 'dhmeManualImportStore/modulesTable',
      placementPhases: 'dhmeManualImportStore/placementPhases',
      isBim: 'dhmeManualImportStore/isBim',
    }),
  },
  async mounted() {
    await this.fetchData();
    window.addEventListener('keydown', this.setEventListeners);
    window.addEventListener('keyup', this.setEventListeners);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.setEventListeners);
    window.removeEventListener('keyup', this.setEventListeners);
  },
  methods: {
    ANT_DELIMITER() {
      return ANT_DELIMITER;
    },
    // -------------------------------
    // 1) DEBOUNCE / QUEUE MECHANISM
    // -------------------------------

    // Handle text-field changes for all columns except 2d_drawing
    handleCellValueChange(recordId, column, newValue) {
      // If we're dealing with 2d_drawing, we do IMMEDIATE update
      if (column === '2d_drawing') {
        this.handle2DDrawingChange(recordId, newValue);
        return;
      }

      // Otherwise, store the change in the local updates queue
      if (!this.updatesQueue[recordId]) {
        this.updatesQueue[recordId] = {};
      }
      this.updatesQueue[recordId][column] = newValue;
      // Kick off a debounced flush
      this.debouncedFlushUpdates();
    },

    // Debounced method to flush the entire queue after user stops typing
    debouncedFlushUpdates() {
      debounce(this.flushUpdates(), 600);
    },

    // Actually send all queued changes to the server, one record at a time
    async flushUpdates() {
      // Copy the queue, then clear it (avoid collisions if more changes come in during this update)
      const queuedChanges = { ...this.updatesQueue };
      this.updatesQueue = {};

      for (const [recordId, recordChanges] of Object.entries(queuedChanges)) {
        try {
          // Update local store to reflect new changes
          let record = this.modules.find((r) => r.id === recordId);
          const oldModuleId = record.module_id;
          if (record) {
            for (let col in recordChanges) {
              record[col] = recordChanges[col];
            }
          }

          const isModuleIdUpdate =
            !!record.model && !!recordChanges['module_id'];

          // Call the store action to update the record in the DB
          await this.$store.dispatch(
            'dhmeManualImportStore/manualImportUpdateRecord',
            {
              recordId: recordId,
              recordBody: recordChanges,
              isModuleIdUpdate,
              oldModuleId,
            }
          );
        } catch (error) {
          console.error('Failed to update record', recordId, error);
          // Optionally revert or show a notification
          this.$store.commit('showNotification', {
            content: `Error updating record ${recordId}`,
            color: 'error',
          });
        }
      }
    },

    // Reset cell (ESC): revert local data & remove from update queue
    resetCell(id, column) {
      let record = this.modules.find((item) => item.id === id);
      if (record) {
        // revert the local displayed value to the last known DB value
        record[column] = this.cellValue;
      }
      // also remove any pending change in the queue for this cell
      if (this.updatesQueue[id] && this.updatesQueue[id][column]) {
        delete this.updatesQueue[id][column];
        if (Object.keys(this.updatesQueue[id]).length === 0) {
          delete this.updatesQueue[id];
        }
      }
      this.selectedCell = null;
      this.cellValue = null;
      this.currentCellValue = null;
    },

    // Move down a row after user hits Enter
    async updateAndRowDown() {
      // We'll flush the queue if needed, but the key is to select the next cell
      let split = this.selectedCell.split(ANT_DELIMITER);
      let columnIndex = this.manualImportModuleHeaders.findIndex(
        (column) => column.value === split[1]
      );
      let recordIndex = this.modules.findIndex(
        (record) => record.id === split[0]
      );

      recordIndex = recordIndex + 1;

      if (
        columnIndex >= 0 &&
        columnIndex <= this.manualImportModuleHeaders.length &&
        recordIndex >= 0 &&
        recordIndex < this.modules.length
      ) {
        this.selectedCell = `${this.modules[recordIndex].id}${ANT_DELIMITER}${this.manualImportModuleHeaders[columnIndex].value}`;
        this.cellValue = this.getCellValue(
          this.modules[recordIndex].id,
          this.manualImportModuleHeaders[columnIndex].value
        );
        this.currentCellValue = this.cellValue;
      }
    },

    // -------------------------------
    // 2) 2D_DRAWING: INSTANT UPDATES
    // -------------------------------
    async handle2DDrawingChange(recordId, file) {
      // If user clears the file input, remove the file from DB
      if (!file) {
        // or we can just call updateRecord to set the column to null
        await this.updateRecord(recordId, '2d_drawing', null);
      } else {
        // If user selects a new file, upload it immediately
        await this.uploadFile(recordId, '2d_drawing', file);
      }
    },

    // -------------------------------
    // 3) FILE UPLOAD METHODS
    // -------------------------------
    async uploadFile(recordId, column, value) {
      if (value) {
        let file = await FileHandlerService.handleFile(value);
        await this.updateRecord(recordId, column, file);
      }
    },
    async upload2DDrawing(file, recordId) {
      const record = this.modules.find((r) => r.id === recordId);
      if (!record) return;

      let { tasks } = await queryTasksV2(this.project.license, [
        {
          column: 'project',
          operator: '=',
          values: [this.project.id],
        },
        {
          column: 'sbscode',
          operator: '=',
          values: [record.build_nr],
        },
        {
          column: 'type',
          operator: '=',
          values: ['dhme-module-assembly'],
        },
      ]);

      if (tasks.length === 1) {
        let assemblyTask = tasks[0];
        let drawing = assemblyTask?.appendixes.find(
          (a) => a.name === `2D_${record.module_id}`
        );
        if (drawing) {
          await deleteTaskAppendix(assemblyTask.id, drawing.id);
        }
        await uploadTaskAppendix(assemblyTask.id, {
          name: `2D_${record.module_id}`,
          extension: file.extension,
          data: file.data,
        });
      }
    },

    // -------------------------------
    // 4) LEGACY METHODS (SOME ADJUSTED)
    // -------------------------------
    async updateRecord(recordId, column, value) {
      this.isLoading = true;
      let body = {};
      body[column] = value;
      try {
        let record = this.modules.find((item) => item.id === recordId);
        const oldModuleId = record.module_id;
        record[column] = value;

        // dispatch update to store
        await this.$store.dispatch(
          'dhmeManualImportStore/manualImportUpdateRecord',
          {
            recordId: recordId,
            recordBody: body,
            isModuleIdUpdate: !!record.model && column === 'module_id',
            oldModuleId,
          }
        );
        this.isLoading = false;

        // if this is a 2D drawing, do the actual file upload
        if (column === '2d_drawing' && value) {
          await this.upload2DDrawing(value, recordId);
        }
      } catch (e) {
        console.log(e);
        this.$store.commit('showNotification', {
          content: 'something went wrong',
          color: 'error',
        });
        // revert changes
        this.resetCell(recordId, column);
      }
    },

    getCellValue(id, column) {
      let record = this.modules.find((item) => item.id === id);
      return record[column];
    },

    selectCell(id, header, value) {
      if (this.deleteMode) {
        // In delete mode, we immediately clear
        this.updateRecord(id, header, null);
      } else {
        this.selectedCell = `${id}${ANT_DELIMITER}${header}`;
        this.cellValue = value;
        this.currentCellValue = value;
      }
    },

    async batchUploadFiles() {
      if (!this.batchFiles || this.batchFiles.length === 0) {
        this.$store.commit('showNotification', {
          content: 'No files selected for upload',
          color: 'error',
        });
        return;
      }

      this.isLoading = true;
      this.batchCounter = 0;

      for (const file of this.batchFiles) {
        const module = this.modules.find((mod) =>
          file.name.includes(mod.module_id)
        );
        if (module) {
          try {
            await this.uploadFile(module.id, '2d_drawing', file);
            this.$store.commit('showNotification', {
              content: `Uploaded file: ${file.name} to module: ${module.build_nr}`,
              color: 'success',
            });
          } catch (error) {
            console.error(`Failed to upload file ${file.name}:`, error);
            this.$store.commit('showNotification', {
              content: `Failed to upload file: ${file.name}`,
              color: 'error',
            });
          }
        } else {
          console.warn(`No matching module found for file: ${file.name}`);
          this.$store.commit('showNotification', {
            content: `No matching module found for file: ${file.name}`,
            color: 'warning',
          });
        }
        this.batchCounter++;
      }

      this.batchFiles = [];
      this.isLoading = false;

      // Refresh module data
      await this.$store.dispatch('dhmeManualImportStore/fetchModules');
    },

    async fetchData() {
      await this.$store.dispatch('dhmeManualImportStore/fetchModules');
      await this.$store.dispatch('dhmeManualImportStore/fetchPlacementPhases');
    },

    async previewDocument(document) {
      let blob = await FileHandlerService.fetchRecordDocument(
        document.id,
        this.project.id,
        this.modulesTableId
      );

      if (blob.type === 'application/pdf' || blob.type.startsWith('image/')) {
        this.$refs['table'].setDocumentPreview(URL.createObjectURL(blob));
      } else {
        FileHandlerService.downloadRecordDocument(
          this.$route.query.document,
          this.selectedProjectTable.project,
          this.selectedProjectTable.id
        );
      }
    },

    // Keyboard shortcuts for arrow navigation
    async setEventListeners(event) {
      if (
        event.ctrlKey &&
        event.altKey &&
        event.code === 'KeyD' &&
        event.type === 'keydown'
      ) {
        this.deleteMode = !this.deleteMode;
        this.selectedCell = null;
      }

      if (this.selectedCell && event.type === 'keydown') {
        if (event.altKey && event.ctrlKey) {
          this.ctrlAltDown = true;
          let split = this.selectedCell.split(ANT_DELIMITER);
          let columnIndex = this.manualImportModuleHeaders.findIndex(
            (column) => column.value === split[1]
          );
          let recordIndex = this.modules.findIndex(
            (record) => record.id === split[0]
          );
          switch (event.key) {
            case 'ArrowLeft':
              // if user changed the cell, we queue that change
              if (this.cellValue !== this.currentCellValue) {
                this.handleCellValueChange(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex - 1;
              this.cellValue = this.getCellValue(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowRight':
              if (this.cellValue !== this.currentCellValue) {
                this.handleCellValueChange(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex + 1;
              this.cellValue = this.getCellValue(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowUp':
              if (this.cellValue !== this.currentCellValue) {
                this.handleCellValueChange(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex - 1;
              this.cellValue = this.getCellValue(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowDown':
              if (this.cellValue !== this.currentCellValue) {
                this.handleCellValueChange(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex + 1;
              this.cellValue = this.getCellValue(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;

            case 'Delete':
              // if user presses Delete, we queue the null
              this.handleCellValueChange(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value,
                null
              );
              this.currentCellValue = null;
              return;
          }
          if (
            columnIndex >= 0 &&
            columnIndex <= this.manualImportModuleHeaders.length &&
            recordIndex >= 0 &&
            recordIndex < this.modules.length
          ) {
            this.selectedCell = `${this.modules[recordIndex].id}${ANT_DELIMITER}${this.manualImportModuleHeaders[columnIndex].value}`;
          }
        } else {
          this.ctrlAltDown = false;
        }
      } else {
        this.ctrlAltDown = false;
      }
    },

    async createRecord() {
      this.isLoading = true;
      let body = {
        build_nr: this.newBuildNr,
      };

      await this.$store.dispatch(
        'dhmeManualImportStore/manualImportCreateRecord',
        body
      );
      this.newBuildNr = null;
      this.isLoading = false;
    },

    setupDelete(module) {
      if (module.model) {
        this.$store.commit('showNotification', {
          content:
            'This module is imported from the bim model you are not allowed to delete it from here',
          color: 'info',
        });
      } else {
        this.moduleToDelete = module;
      }
    },
    async deleteModule() {
      this.isLoading = true;
      await this.$store.dispatch(
        'dhmeManualImportStore/manualImportDeleteRecord',
        this.moduleToDelete.id
      );
      this.moduleToDelete = null;
      this.isLoading = false;
    },

    async importCsvData() {
      if (this.csvImportFile.name.split('.').pop() !== 'csv') {
        this.$store.commit('showNotification', {
          content: 'Only CSV files are accepted',
          color: 'error',
        });
        this.csvImportFile = undefined;
      } else {
        let body = {};

        await FileHandlerService.handleFile(this.csvImportFile).then(
          (value) => {
            body.records = value.data;
          }
        );

        this.$store
          .dispatch('importRecords', {
            tableId: this.modulesTableId,
            data: body,
          })
          .then(() => {
            this.csvImportFile = undefined;
            this.$store.dispatch('dhmeManualImportStore/fetchModules');
          });
      }
    },
    async setWorkloadForModules() {
      if (!/^\d+$/.test(this.workloadValue)) {
        this.$store.commit('showNotification', {
          content: 'Decimals are not allowed, please enter a whole number.',
          color: 'warning',
        });
        return;
      }
      this.workloadLoading = true;
      this.selectedCell = null;

      try {
        let mapped = this.modules.map((mod) => {
          return {
            id: mod.id,
            assembly_workload: this.workloadValue,
          };
        });

        let book = bookOfObjects('records', mapped);
        let csv = book.convert('csv', 'string');
        let parsedCsv = btoa(unescape(encodeURIComponent(csv)));

        await importRecords({
          project: {
            id: this.project.id,
          },
          table: {
            id: this.modulesTableId,
          },
          records: parsedCsv,
        });
        await this.$store.dispatch('dhmeManualImportStore/fetchModules');
        this.workloadMenu = false;
        this.workloadValue = 32;
        this.$refs.workloadForm.reset();
      } catch (e) {
        console.log(e);
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.workloadLoading = false;
      }
    },
    async importFromLayout() {
      this.importLoading = true;
      let { buildings } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_DATA',
            project: this.project.master_id,
            as: 'metadata',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.project.id,
                  },
                ],
              },
              {
                name: 'firm',
              },
            ],
          },
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_BUILDINGS',
            project: this.project.master_id,
            as: 'buildings',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.project.id,
                  },
                ],
              },
              {
                name: 'ordinal',
              },
            ],
          },
        ],
      });

      let { layers } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_BUILDING_LAYERS',
            project: this.project.master_id,
            as: 'layers',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.project.id,
                  },
                ],
              },
              {
                name: 'building_id',
                conditions: [
                  {
                    operator: '=',
                    values: buildings.records.map((x) => x.id),
                  },
                ],
              },
              {
                name: 'layer',
              },
              {
                name: 'module_count',
              },
            ],
          },
        ],
      });

      let modules = [];
      let totalCount = layers.records.reduce(
        (total, l) => total + l.module_count,
        0
      );
      let totalIndex = 1;
      buildings.records.forEach((b) => {
        const buildingLayers = layers.records.filter(
          (l) => l.building_id === b.id
        );
        buildingLayers.forEach((l) => {
          for (let i = 1; i <= l.module_count; i++) {
            let obj = { id: null };
            let padded_i = String(i).padStart(
              Math.max(String(totalCount).length, 2),
              '0'
            );
            obj.build_nr = `${this.getColumnLabel(b.ordinal)}${l.layer}.${padded_i}`;
            obj.id =
              this.modules.find((m) => m.build_nr === obj.build_nr)?.id ?? null;
            modules.push(obj);
            totalIndex++;
          }
        });
      });

      const newBuildNumbers = new Set(modules.map((mod) => mod.build_nr));

      const buildNrsToDelete = this.modules
        .filter((mod) => !newBuildNumbers.has(mod.build_nr))
        .map((mod) => mod.id);

      if (buildNrsToDelete.length > 0) {
        await deleteRecords({
          project: {
            id: this.project.id,
          },
          table: {
            id: this.modulesTableId,
          },
          records: [...buildNrsToDelete],
        });
      }

      let book = bookOfObjects('records', modules);
      let csv = book.convert('csv', 'string');
      let parsedCsv = btoa(unescape(encodeURIComponent(csv)));

      await importRecords({
        project: {
          id: this.project.id,
        },
        table: {
          id: this.modulesTableId,
        },
        records: parsedCsv,
      });
      await this.$store.dispatch('dhmeManualImportStore/fetchModules');
      this.importMenu = false;
      this.importLoading = false;
    },

    getColumnLabel(index) {
      let columnName = '';
      let dividend = index;
      let modulo;

      while (dividend > 0) {
        modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = parseInt((dividend - modulo) / 26);
      }

      return columnName;
    },

    exportToCsv() {
      const columnOrder = [
        'id',
        'build_nr',
        'module_id',
        'module_type',
        'placement_sequence',
        'assembly_sequence',
        'assembly_workload',
        'house_nr',
        'phase',
      ];
      return this.modules.map((record) => {
        const orderedRecord = {};
        columnOrder.forEach((key) => {
          orderedRecord[key] = record[key] ?? '';
        });
        return orderedRecord;
      });
    },
  },
});
</script>

<template>
  <dynamic-data-table
    ref="table"
    :csv-export-function="exportToCsv"
    :is-loading="isLoading"
    :project-id="project.id"
    :table-headers="manualImportModuleHeaders"
    :table-id="modulesTableId"
    :table-records="modules"
    class="flex-grow-1 ant-glass-background radius-0"
    export-file-name="manual_import_module_export"
    has-options
    table-title="Modules"
    can-delete
    @deleteItem="setupDelete"
  >
    <!-- TABLE BUTTONS -->
    <template #table-buttons>
      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            :color="deleteMode ? 'primary' : ''"
            v-bind="attrs"
            @click="deleteMode = !deleteMode"
            v-on="on"
          >
            {{ deleteMode ? 'mdi-delete-empty' : 'mdi-delete-empty-outline' }}
          </v-icon>
        </template>
        <span>Toggle delete mode (Ctrl+Alt+D)</span>
      </v-tooltip>

      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            :color="ctrlAltDown ? 'primary' : ''"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
          >
            mdi-arrow-all
          </v-icon>
        </template>
        <span
          >You can use Ctrl+Alt+Arrow keys to move from cell to cell when a cell
          is selected</span
        >
      </v-tooltip>
    </template>

    <!-- TABLE OPTIONS MENU -->
    <template #table-options-menu>
      <v-list-item @click.stop="$refs['batch-file-input'].$refs.input.click()">
        <v-list-item-icon style="margin-right: 10px">
          <v-icon dense>mdi-folder-upload</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Batch Upload Appendices</v-list-item-title>
      </v-list-item>
      <v-file-input
        ref="batch-file-input"
        v-model="batchFiles"
        accept="application/pdf"
        multiple
        style="display: none"
        @change="batchUploadFiles"
      />

      <v-menu
        v-if="!isBim"
        v-model="importMenu"
        :close-on-content-click="false"
        left
      >
        <template #activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon style="margin-right: 10px">
              <v-icon>mdi-progress-star-four-points</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Import Modules</v-list-item-title>
          </v-list-item>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Import modules</v-list-item-title>
                <v-list-item-subtitle>
                  Based upon layout given in
                  <span class="font-weight-bold">Project Intake</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <ant-input is-optional label="Ignore module types">
                <template #input-field>
                  <v-checkbox
                    v-model="ignoreModuleTypes"
                    :label="
                      ignoreModuleTypes
                        ? 'Module Types Ignored'
                        : 'Module Types used'
                    "
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                </template>
              </ant-input>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="importLoading"
              small
              text
              @click="importMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled="importLoading"
              :loading="importLoading"
              color="primary"
              elevation="0"
              small
              @click="importFromLayout"
            >
              Import
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-menu v-model="workloadMenu" :close-on-content-click="false" left>
        <template #activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon style="margin-right: 10px">
              <v-icon>mdi-update</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Set workload for all modules</v-list-item-title>
          </v-list-item>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Set workload</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-form ref="workloadForm">
                <ant-input is-optional label="Workload (working hours)">
                  <template #input-field>
                    <v-text-field
                      v-model="workloadValue"
                      type="number"
                      filled
                      outlined
                      :min="1"
                    />
                  </template>
                </ant-input>
              </v-form>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="workloadLoading"
              small
              text
              @click="workloadMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="workloadLoading"
              :disabled="workloadLoading"
              color="primary"
              elevation="0"
              small
              @click="setWorkloadForModules"
            >
              Update All
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-list-item
        v-if="!isBim"
        @click.stop="$refs['csv-import'].$refs.input.click()"
      >
        <v-list-item-icon style="margin-right: 10px">
          <v-icon dense> mdi-file-import</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Import CSV</v-list-item-title>
        <v-file-input
          ref="csv-import"
          v-model="csvImportFile"
          accept=".csv"
          style="display: none"
          @change="importCsvData()"
        />
      </v-list-item>
    </template>

    <!-- TABLE ACTIONS -->
    <template #table-actions>
      <v-chip v-if="deleteMode" class="mr-2" color="warning">
        <v-icon>mdi-exclamation-thick</v-icon>
        DELETE MODE ACTIVE
      </v-chip>
      <template v-if="batchCounter > 0 && batchCounter < batchFiles.length">
        Uploading: {{ batchCounter }} / {{ batchFiles.length }}
        <v-progress-circular
          :value="(batchCounter / batchFiles.length) * 100"
          class="mr-5 ml-2"
          color="primary"
          indeterminate
          size="20"
        />
      </template>

      <v-text-field
        v-if="!isBim"
        v-model="newBuildNr"
        :append-outer-icon="newBuildNr?.length >= 2 ? 'mdi-plus' : ''"
        class="normal-text-field"
        clearable
        dense
        filled
        hide-details
        placeholder="New build nr"
        single-line
        @keydown.enter="createRecord"
        @click:append-outer="createRecord"
        @keydown.esc="newBuildNr = null"
      />
      <delete-dialog
        :dialog="moduleToDelete !== null"
        :title="`Are you sure you want to delete module ${moduleToDelete?.build_nr}`"
        @closeDialog="moduleToDelete = null"
        @deleteAction="deleteModule"
      />
    </template>

    <!-- TABLE COLUMNS -->
    <!-- For all "editable" columns with text fields -->
    <template #item.model="{ item }">
      <v-chip v-if="item.model" color="info" x-small>BIM</v-chip>
      <v-chip v-else color="warning" x-small>Manual</v-chip>
    </template>
    <template
      v-for="header in manualImportModuleHeaders.filter((x) => x.input)"
      #[`item.${header.value}`]="{ value, item }"
    >
      <td :key="header.value" class="ant-border-left">
        <v-text-field
          v-if="selectedCell === `${item.id}${ANT_DELIMITER()}${header.value}`"
          v-model="currentCellValue"
          :readonly="
            item.model && ['build_nr', 'module_type'].includes(header.value)
          "
          :disabled="isBim && !item.model"
          :type="header.type"
          autofocus
          dense
          filled
          hide-details
          single-line
          @change="handleCellValueChange(item.id, header.value, $event)"
          @keydown.enter="updateAndRowDown"
          @keydown.esc="resetCell(item.id, header.value)"
        />
        <div
          v-else
          :style="{
            cursor:
              item.model && ['build_nr', 'module_type'].includes(header.value)
                ? 'not-allowed'
                : 'pointer',
          }"
          class="full-height full-width d-flex align-center justify-center"
          @click="
            item.model && ['build_nr', 'module_type'].includes(header.value)
              ? null
              : selectCell(item.id, header.value, value)
          "
        >
          <!-- Show a warning icon if required but empty -->
          <v-tooltip bottom color="warning">
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="header.required && !value"
                color="warning"
                v-bind="attrs"
                v-on="on"
                >mdi-alert
              </v-icon>
            </template>
            <span>Required</span>
          </v-tooltip>
          {{ value }}
        </div>
      </td>
    </template>

    <!-- PHASE SELECT -->
    <template #item.phase="{ value, rowId, item }">
      <td class="ant-border-left">
        <v-select
          v-if="selectedCell === `${item.id}${ANT_DELIMITER()}phase`"
          :items="placementPhases"
          :value="value"
          class="normal-text-field"
          clearable
          dense
          filled
          hide-details
          item-text="title"
          item-value="id"
          single-line
          @change="handleCellValueChange(rowId, 'phase', $event)"
        />
        <div
          v-else
          class="full-height full-width d-flex align-center justify-center"
          @click="selectCell(item.id, 'phase', value)"
        >
          {{
            placementPhases.find((phase) => phase.id === value)?.title ?? null
          }}
        </div>
      </td>
    </template>

    <!-- 2D_DRAWING: INSTANT UPDATES -->
    <template #item.2d_drawing="{ value, rowId, item }">
      <td class="ant-border-left">
        <!-- For a file input, we do immediate updates -->
        <v-file-input
          v-if="selectedCell === `${item.id}${ANT_DELIMITER()}2d_drawing`"
          :value="value"
          accept="application/pdf"
          class="normal-text-field"
          clearable
          dense
          filled
          hide-details
          single-line
          @change="handle2DDrawingChange(rowId, $event)"
        />
        <div
          v-else
          class="full-height full-width d-flex align-center justify-center"
          @click="selectCell(item.id, '2d_drawing', value)"
        >
          <span v-if="value"> {{ value?.name }}.{{ value?.extension }} </span>
          <v-icon v-if="value" class="ml-2" @click.stop="previewDocument(value)"
            >mdi-eye
          </v-icon>
        </div>
      </td>
    </template>
  </dynamic-data-table>
</template>

<style lang="scss" scoped>
td {
  padding: 0 !important;
}

::v-deep .v-text-field__details {
  height: 0;
  overflow: hidden;
  min-height: 0;
}

::v-deep .x-small-text-field {
  max-width: 100px !important;
}

::v-deep .small-text-field {
  max-width: 150px !important;
}

::v-deep .normal-text-field {
  max-width: 200px !important;
}
</style>
